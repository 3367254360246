import api from '@/plugins/api'
import { getTimeStamp } from '@/helper/get-time-stamp'

const state = () => ({ data: '', loading: false, error: '' })

const mutations = {
  LOADING: (s, loading) => (s.loading = loading),
  LOADED: (s, loaded) => (s.loading = loaded),
  SET_DATA: (s, data) => (s.data = data),
  SET_ERROR: (s, error) => (s.error = error),
  RESET: s => Object.assign(s, state()),
}

const actions = {
  async detailAsync({ commit, dispatch }, { id, race, loading, loaded }) {
    commit('LOADING', loading)
    const time = getTimeStamp()
    try {
      const { data } = await api.get(`event/prices/${id}/${race}?rand=${time}`)
      data.runners.sort(
        (scrat1, scrat2) => scrat1.isScratched - scrat2.isScratched
      )
      const listRacing = await dispatch('racing/list/getAsync', data, {
        root: true,
      })
      if (!data || !listRacing) {
        console.log('get api fail...')
        return
      }

      const findRace = listRacing.HORS.find(x => x.meetingId === data.meetingId)
      const mapData = findRace.races.map(item => {
        const race = [item].find(x => x.raceNumber === data.eventNumber)
        if (race) {
          return {
            ...race,
            country: findRace.country,
            result: race.result || '',
            status: race.status.toUpperCase(),
            meetingId: data.meetingId,
            name: data.name,
            eventNumber: data.eventNumber,
            runners: data.runners,
            utcTime: data.utcTime,
            meetingName: findRace.meetingName,
            numberOfRaces: findRace.numberOfRaces,
            races: findRace.races,
            sportCode: findRace.sportCode,
          }
        }
      })
      const result = mapData.find(element => element)
      commit('SET_DATA', result)
    } catch (err) {
      console.log(err)
    } finally {
      commit('LOADED', loaded)
    }
  },

  reset({ commit }) {
    commit('RESET')
  },
}

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
}
