<template lang="pug">
div
  .input-control
    label.control-checkbox Average Odds
      input(type='checkbox', v-model='isAverage')
      .control-indicator
  .average-list(v-if='isAverage')
    OpenAverageItem(v-for='(item, key) in listData', :key='key', :item='item')
  .data-list(v-else)
    OpenDataItem(
      v-for='(item, key) in listBetData',
      :key='key',
      :betData='item.betData',
      :itemInId='item._id'
    )
</template>

<script>
import { arrayProp } from '@/helper/props'
import OpenAverageItem from './OpenAverageItem'
import OpenDataItem from './OpenDataItem'
export default {
  components: {
    OpenAverageItem,
    OpenDataItem,
  },
  props: {
    listData: arrayProp(),
    listBetData: arrayProp(),
  },

  data() {
    return {
      isAverage: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.input-control {
  position: relative;
  user-select: none;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 14px;
  border-bottom: 1px solid rgb(0 0 0 / 12%);
  .control-checkbox {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-weight: 700;
    color: #000;
    font-size: 0.8rem;
    width: max-content;
    input {
      position: relative;
      height: 0;
      width: 0;
      overflow: hidden;
      margin-left: 0;
      z-index: -1;
    }
    input:checked ~ .control-indicator {
      background: var(--colorOrange);
      border: 2px solid var(--colorOrange);
      &:hover:before {
        background-color: var(--colorOrange);
      }
    }
  }

  .control-indicator {
    order: -1;
    margin-right: 14px;
    height: 15px;
    width: 15px;
    position: relative;
    top: -2px;
    background: #fff;
    border-radius: 3px;
    border: 2px solid rgba(0, 0, 0, 0.35);
    &:before {
      border-radius: 50%;
      bottom: 0;
      content: '';
      position: absolute;
      opacity: 0.2;
      left: 0;
      right: 0;
      top: 0;
      transform-origin: center center;
      transform: scale(0.2);
      transition: inherit;
      z-index: 4;
    }
    &:after {
      content: '';
      position: absolute;
      left: 3px;
      top: -1px;
      width: 3px;
      height: 8px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    &:hover:before {
      background: currentColor;
      transform: scale(3);
      transition: none;
    }
  }
}
</style>
