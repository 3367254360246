<template lang="pug">
.error-validate
  div(v-if='isDisplayedErrorMessage("required")')
    slot(name='error-msg-required') {{ name }} is required
  div(v-if='isDisplayedErrorMessage("minValue")')
    slot(name='error-msg-minValue') Min. {{ data.$params.minValue.min }}
  div(v-if='isDisplayedErrorMessage("maxValue")')
    slot(name='error-msg-maxValue') Max. {{ data.$params.maxValue.max }}
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => {} },
    name: { type: String, default: '' },
  },
  computed: {
    isShowFeedback() {
      if (!this.data) return false

      const { $error } = this.data

      return !!$error
    },
  },
  methods: {
    isDisplayedErrorMessage(validator) {
      return (
        Object.prototype.hasOwnProperty.call(this.data, validator) &&
        !this.data[validator] &&
        this.isShowFeedback
      )
    },
  },
}
</script>
