import { render, staticRenderFns } from "./BetSlip.vue?vue&type=template&id=2a2392ba&scoped=true&lang=pug&"
import script from "./BetSlip.vue?vue&type=script&lang=js&"
export * from "./BetSlip.vue?vue&type=script&lang=js&"
import style0 from "./BetSlip.vue?vue&type=style&index=0&id=2a2392ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a2392ba",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/Users/hiepngo/Code/Appscyclone/play2win/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseLoadingSpinner from '@/components/base/BaseLoadingSpinner.vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseSwitch from '@/components/base/BaseSwitch.vue'
autoInstallComponents(component, {BaseButton,BaseIcon,BaseLoadingSpinner,BaseModal,BaseSwitch})
