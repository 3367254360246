<template lang="pug">
.video
  video.c1(controls)
    source(src='')
  .btnWrap.c2
    .btnChanel
      BaseButton(type='primary') Aus 1
      BaseButton.active(type='primary') Aus 2
      BaseButton(type='primary') United States
      BaseButton(type='primary') Japan
</template>
<script>
export default {
  name: 'VideoSection',
}
</script>

<style lang="scss" scoped>
.video {
  display: grid;
  grid:
    'c1 c1'
    'c2 c2'
    / 8fr 4fr;
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    display: grid;
    gap: 35px;
    grid: 'c1 c2'/ 10fr 2fr;
  }
  video {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      width: 80%;
    }
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
  }

  .btnWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 1024px) {
      align-items: flex-end;
    }
    .btnChanel {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      @media (min-width: 1024px) {
        align-items: stretch;
        flex-direction: column;
      }
      .btn {
        margin-right: 10px;
        margin-bottom: 20px;
        font-size: 0.625rem;
        &.active {
          color: #fff;
          background: var(--gradientOrange);
        }
        @media (min-width: 1024px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
