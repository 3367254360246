<template lang="pug">
.bet-race(v-if='screenWidth >= 960')
  .nav
    .head
      .bet-slip(
        @click='setActiveTab(TITLE_BET.BETSLIP)',
        :class='{ active: dataActiveTabs === TITLE_BET.BETSLIP, disabled: isDisabled }'
      ) BET SLIP
      .open-bet(
        @click='setActiveTab(TITLE_BET.OPENBET)',
        :class='{ active: dataActiveTabs === TITLE_BET.OPENBET }'
      ) OPEN BETS
    .main
      BetSlip(:class='{ "active show": dataActiveTabs === TITLE_BET.BETSLIP }')
      OpenBet(:class='{ "active show": dataActiveTabs === TITLE_BET.OPENBET }')
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TITLE_BET } from '@/assets/constants'
import BetSlip from './partial/BetSlip'
import OpenBet from './partial/OpenBet'
export default {
  components: {
    BetSlip,
    OpenBet,
  },

  data() {
    return {
      TITLE_BET,
      isDisabled: false,
      screenWidth: screen.availWidth,
    }
  },

  computed: {
    ...mapState('activeTabs', {
      dataActiveTabs: 'data',
    }),
  },

  methods: {
    ...mapActions({
      setActiveTab: 'activeTabs/setActiveTab',
    }),
  },
}
</script>

<style lang="scss" scoped>
.bet-race {
  font-weight: 600;
  color: gray;
  flex-direction: column;
  margin-top: 20px;
  display: none;
  position: relative;
  z-index: 10;
  @media (min-width: 960px) {
    margin-top: 0;
    display: flex;
  }
  .nav {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    transition: all 0.25s ease;
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--colorBgDark);
    > * {
      position: relative;
      padding: 15px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 3px;
      cursor: pointer;
      &:before {
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      }
      &:hover:before {
        opacity: 0.25;
        background-color: currentColor;
      }
    }
    .bet-slip {
      position: relative;
      &.active {
        color: var(--colorOrange);
        + .open-bet::after {
          opacity: 1;
          left: -82px;
          width: 82px;
        }
      }
      &.disabled {
        pointer-events: none;
      }
    }
    .open-bet {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        right: 0;
        height: 1.7px;
        background-color: var(--colorOrange);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      }
      &.active {
        color: var(--colorOrange);
        &::after {
          opacity: 1;
          width: 99px;
        }
      }
    }
  }
}
</style>
