const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const formatCurrency = number => {
  if (!number) {
    return formatter.format(0)
  }
  return formatter.format(number)
}

export const formatPrice = val => {
  if (typeof val !== 'number') return '0.00'
  if (!val) {
    return '0.00'
  }
  return val.toFixed(2)
}
