<template lang="pug">
.modalBlock
  .modalFrame(:class='{ stateAppear: open }')
    .modal
      .modalInset(:class='classess')
        .modalBody
          slot
    .modalOverlay(:class='{ stateShow: open }', @click='$emit("click")')
</template>

<script>
import { stringProp, booleanProp } from '@/helper/props'

export default {
  name: 'BaseModal',
  props: {
    open: booleanProp(),
    classess: stringProp(),
  },
}
</script>

<style lang="scss" scoped>
.modalOverlay {
  position: fixed;
  inset: 0;
  height: 100vh;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  transition: opacity 0.25s ease 0s, visibility 0.35s linear;
  z-index: 40;
  &.stateShow {
    opacity: 0.7;
    visibility: visible;
    transition-delay: 0s;
    transition-duration: 0.2s, 0s;
  }
}
.modalFrame {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  text-align: center;
  visibility: hidden;
  z-index: 50;
  &.stateAppear {
    visibility: visible;
    .modalInset {
      animation: modalComeIn 0.25s ease;
      visibility: visible;
    }
    .modalBody {
      opacity: 1;
      transform: translateY(0) scale(1, 1);
    }
  }
}
.modal {
  position: relative;
  display: block;
  text-align: center;
  z-index: 999;
}
.modalInset {
  position: relative;
  min-width: 320px;
  min-height: 126px;
  margin: auto;
  padding: 10px;
  border-radius: 4px;
  visibility: hidden;
  backface-visibility: hidden;
  background-color: white;
  box-shadow: 2px 2px 8px 1px rgb(0 0 0 / 20%);
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  &.secondary {
    background-color: var(--colorSecondary);
  }
  &.accent {
    background-color: var(--colorThird);
  }
}
.modalBody {
  margin: auto;
  opacity: 0;
  transform: translateY(0) scale(0.8, 0.8);
  transition: opacity 0.25s, transform 0.25s;
}
</style>
