export const convertTimeStamp = stringDate => {
  const timeStamp = new Date(stringDate)
  const raceHours = timeStamp.getHours()
  const raceMinutes = String(timeStamp.getMinutes()).padStart(2, '0')

  if (raceHours >= 12) {
    return `${raceHours}:${raceMinutes} PM`
  }

  return `${raceHours}:${raceMinutes} AM`
}
