import verifyBet from './modules/verify-bet'
import quickBet from './modules/quick-bet'
import transStatus from './modules/trans-status'
import transDebit from './modules/trans-debit'
import openBet from './modules/open-bet'

/** @type {import('vuex').Module} */

export default {
  namespaced: true,
  modules: { verifyBet, quickBet, transStatus, transDebit, openBet },
}
