<template lang="pug">
.bet-item
  BaseIcon.float-close(
    v-if='!isConfirm',
    name='close',
    @click.native='handleRemoveBet'
  )
  p {{ item.meetingName }}
  p {{ `${item.runnerNumber}. ${item.outcomeName} - ${item.betType}` }}
  .block-odds(v-if='!isConfirm')
    BaseInputStake(
      v-model='odds',
      label='Odds',
      name='Odds',
      type='number',
      min=100,
      max=1000000,
      :disabled='true',
      :readonly='true',
      placeholder='0.00'
    )
    BaseInputStake(
      v-model='$v.stake.$model',
      label='Stake',
      type='number',
      min=1,
      max=100,
      name='Stake',
      :validation='$v.stake',
      @blur='$v.stake.$touch()',
      @input='handleInputStakeBet',
      :placeholder='`Min. 1 Max. ${maxValueStake}`'
    )
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { objectProp, numberProp, booleanProp } from '@/helper/props'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'

export default {
  props: {
    item: objectProp(),
    index: numberProp(),
    isConfirm: booleanProp(),
  },
  data() {
    return {
      odds: 0,
      stake: '',
    }
  },

  validations() {
    return {
      stake: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(this.maxValueStake),
      },
    }
  },

  computed: {
    ...mapState('bet', ['betActive', 'items']),
    ...mapState('auth', ['multiplier']),

    maxValueStake() {
      if (!this.multiplier) return 100
      return +this.multiplier * 100
    },
  },

  watch: {
    item: {
      immediate: true,
      deep: true,
      handler(val) {
        this.odds = val.odds
        this.stake = val.stake
        const vStake = this.$v.stake
        if (!vStake.maxValue || !vStake.minValue || !vStake.required) {
          this.setError(true)
        } else {
          this.setError(false)
        }

        if (!vStake.maxValue || !vStake.minValue) vStake.$touch()
      },
    },
  },

  methods: {
    ...mapActions({
      doUpdateStakeBetItem: 'bet/updateStakeBetItem',
      doSetActiveBet: 'bet/setActiveBet',
      doRemoveBet: 'bet/removeBet',
      setError: 'bet/setError',
    }),

    handleInputStakeBet(val) {
      const vStake = this.$v.stake
      if (!vStake.required) this.items[0].stake = ''
      this.doUpdateStakeBetItem({ index: this.index, stake: val })
    },

    handleRemoveBet() {
      this.doRemoveBet(this.index)
    },
  },
}
</script>

<style lang="scss" scoped>
.bet-item {
  position: relative;
  .float-close {
    position: absolute;
    // top: 32px;
    right: 0;
    width: 10px;
    height: auto;
    cursor: pointer;
  }
  p {
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.0333333333em;
    color: rgba(0, 0, 0, 0.87);
  }
  .block-odds {
    margin: 20px 0 0;
    display: grid;
    grid: 'c1 c2'/1fr 1fr;
    gap: 20px;
  }
  .footer {
    text-align: right;
    margin-bottom: 15px;
    p {
      font-size: 0.75rem;
    }
  }
}
</style>
