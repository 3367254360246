export const currentDay = (exactJsMonth = false) => {
  const now = new Date()
  const month = exactJsMonth ? now.getMonth() : now.getMonth() + 1
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'Jun',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  return {
    date: ('0' + now.getDate()).slice(-2),
    month,
    Month: monthNames[now.getMonth()],
    year: now.getFullYear().toString().substr(-2),
    fullYear: now.getFullYear(),
  }
}
