import Vue from 'vue'
import { getTimeStamp } from '@/helper/get-time-stamp'
import api from '@/plugins/api'

const state = () => ({
  loading: false,
  error: null,
  betListProcessing: [],
})

const mutations = {
  LOADING: s => Object.assign(s, state(), { loading: true }),
  LOADED: state => (state.loading = false),
  SET_ERROR: (s, error) => (s.error = error),
  SET_BET_PROCESSING: (s, payload) => (s.betListProcessing = payload),
  RESET: s => Object.assign(s, state()),
}

const actions = {
  async transStatusAsync({ commit, rootState }, payload) {
    const time = getTimeStamp()
    commit('LOADING')
    try {
      const params = {
        ut: rootState.auth.accessToken,
        tids: payload.map(item => item.tid).filter(el => el),
      }
      const res = await api.post(`u/TransStatus?rand=${time}`, params)
      if (res.data.pgs) {
        const tempListMapping = payload.map(item => {
          const tempTransaction = res.data.pgs.find(x => x.Key === item.tid)
          return {
            ...item,
            sts: tempTransaction ? tempTransaction.Value.sts : '',
          }
        })
        const tempBet = await rootState.bet.items.map((bet, i) => ({
          ...bet,
          sts: tempListMapping[i].sts,
          key: tempListMapping[i].tid || '',
          cli: tempListMapping[i].cli || '',
          em: '',
        }))
        Vue.notify({
          type: 'success',
          title: 'Success',
          text: 'Place bet success!',
        })
        commit('bet/SET_LIST_BET_VERIFY', [...tempBet], { root: true })

        // set item processing
        const processingArray = tempBet.filter(
          item => item.sts === 'Processing'
        )
        if (processingArray.length > 0)
          commit('SET_BET_PROCESSING', processingArray)
      }
    } catch (e) {
      console.log(e)
    } finally {
      commit('LOADED')
    }
  },

  reset({ commit }) {
    commit('RESET')
  },
}

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
}
