<template lang="pug">
.open-bet
  .item
    .panel-head(@click='collapseUnmatched()')
      .header
        .title Unmatched Bets
        div
      BaseIcon.icon(name='arrow-up', :class='{ rotate: collapse }')
      .panel-content(ref='unmatched', :style='{ collapse: collapse }')
  .item
    .panel-head(@click='collapseMatched()')
      .header
        .title Matched Bets
        div
      BaseIcon.icon(name='arrow-up', :class='{ rotate: collapse2 }')
    .panel-content.scrollbar-custom(ref='matched', :class='{ collapse: collapse2 }')
      .content(v-if='data.length > 0')
        OpenBetItem(:listData='listData', :listBetData='listBetData')

      .empty-content(v-else)
        BaseIcon.icon(name='warning')
        p No matched bets!
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import OpenBetItem from './OpenBetItem'

export default {
  components: {
    OpenBetItem,
  },

  data() {
    return {
      isSubmit: false,
      collapse: true,
      collapse2: true,
    }
  },

  computed: {
    ...mapGetters('bet', ['listBetConfirm']),
    ...mapState('betting/openBet', ['data', 'loading']),
    ...mapState('auth', ['accessToken']),

    listData() {
      const listBetData = []
      this.data.map(item => item.betData.map(i2 => listBetData.push(i2)))
      return listBetData
    },

    listBetData() {
      const listBetData = this.data.reduce((prev, cur) => {
        const index = prev.findIndex(v => v._id.marketId === cur._id.marketId)
        if (index === -1) {
          prev.push(cur)
        } else {
          prev[index].betData.push(...cur.betData)
        }

        return prev
      }, [])
      return listBetData
    },
  },

  async created() {
    if (!Object.entries(this.$route.query).length) return
    const accessToken = this.$route.query.accessToken.replace('/', '')
    await this.getOpenBet({
      _accessToken: accessToken,
      isRacebook: true,
    })
  },

  methods: {
    ...mapActions({
      setActiveTab: 'activeTabs/setActiveTab',
      getOpenBet: 'betting/openBet/openBetAsync',
    }),
    collapseUnmatched() {
      this.collapse = !this.collapse
    },
    collapseMatched() {
      this.collapse2 = !this.collapse2
    },
  },
}
</script>

<style lang="scss" scoped>
.open-bet {
  font-size: 0.75rem !important;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  font-weight: 400;
  display: block;
  @media (min-width: 960px) {
    display: none;
  }
  &.show {
    display: block;
  }
}

.item {
  background: var(--colorBgGray);
  border-color: var(--colorBgGray);
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  & .panel-head {
    max-width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    & .header {
      flex: 1 1 auto;
      .title {
        position: relative;
        background-color: var(--colorSuccess2);
        border-color: var(--colorSuccess2);
        min-height: 40px;
        color: #fff;
        padding-top: 8px;
        padding-left: 8px;
        &:before {
          content: '';
          -webkit-clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
          clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
          width: 15px;
          height: 100%;
          background-color: var(--colorSuccess2);
          position: absolute;
          right: -14px;
          top: 0;
        }
      }
      div {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    & .icon {
      width: 12px;
      height: 12px;
      margin-right: 15px;
      transform: rotate(180deg);
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      &.rotate {
        transform: rotate(0);
      }
    }
  }
  & .panel-content {
    max-width: 100%;
    background: #fff;
    overflow-y: scroll;
    max-height: 0;
    transition: max-height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    &.collapse {
      max-height: 5000px;
      transition: max-height 1s ease-in-out;
    }
    .empty-content {
      display: flex;
      padding: 20px;
      min-height: 150px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--colorOrange);
      font-weight: 700;
      font-size: 12px;
      .icon {
        width: 45px;
        height: 45px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
