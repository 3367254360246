import Vue from 'vue'
import { formatCurrency } from '@/helper/formater'
import { format } from 'date-fns'

Vue.filter('formatCurrency', number => {
  return formatCurrency(number)
})

Vue.filter('formatDate', (datetime, fm = 'MM/dd/yyyy') => {
  if (!datetime) return 'MM/dd/yyyy'
  const date = parseInt(datetime.replace('/Date(', '').replace(')/', ''))
  return format(new Date(date), fm)
})

Vue.filter('formatDate2', (datetime, fm = 'MM/dd/yyyy') => {
  if (!datetime) return 'MM/dd/yyyy'
  return format(new Date(datetime), fm)
})

Vue.filter('slideDateTime', datetime => {
  if (typeof datetime === 'string')
    return parseInt(datetime.replace('/Date(', '').replace(')/', ''))
  return '-'
})

Vue.filter('slideDateTime2', datetime => {
  if (typeof datetime === 'string')
    return parseInt(datetime.replace('T06:25:00', ''))
  return '-'
})

Vue.filter('formatPrice', value => {
  if (!value) return '-'
  return value ? parseFloat(value).toFixed(2) : value
})
