import Vue from 'vue'
import $api from '@/helper/axios-instance'

const state = () => ({
  data: [],
  loading: false,
  success: false,
})

const mutations = {
  LOADING: s => Object.assign(s, state(), { loading: true }),
  LOADED: s => (s.loading = false),
  SET_DATA: (s, data) => (s.data = data),
  SET_SUCCESS: (s, payload) => (s.success = payload),
  RESET: s => Object.assign(s, state()),
}

const actions = {
  async openBetAsync({ commit }, payload) {
    commit('LOADING')
    try {
      const baseURL = process.env.VUE_APP_SPORT_API_URL
      const { data } = await $api.post(
        `${baseURL}bet/getMyCurrentBetStatusNew`,
        payload
      )
      if (data.error) {
        Vue.notify({
          type: 'error',
          text: data.error.message,
        })
      }
      commit('SET_DATA', data.data.data)
      commit('SET_SUCCESS', true)
    } catch (error) {
      console.log(error)
    } finally {
      commit('LOADED')
    }
  },
  reset({ commit }) {
    commit('RESET')
  },
}

export default {
  namespaced: true,
  state: state,
  actions,
  mutations,
}
