<template lang="pug">
.nav
  .head
    .title
      BaseIcon(name='thoroughbreds-range')
      span Horse Racing
    button.back(@click='onToPrevious')
      BaseIcon(name='arrow-back')
      span Previous
  ul.list-race.scrollbar-custom
    li.item-race(
      v-for='(race, index) in raceToGo',
      @click='onToRace(race.meetingId, race.raceNumber)'
    )
      BaseIcon.icon(name='thoroughbreds-range')
      span.time {{ convertTimeStamp(race.startTime) }}
      .info
        p R{{ race.raceNumber }} {{ race.meetingName }} ({{ race.country }})
</template>
<script>
import { stringProp, arrayProp } from '@/helper/props'
import { STATUS_RACE } from '@/assets/constants'
import { convertTimeStamp } from '@/helper/convert-time-stamp.js'

export default {
  props: {
    name: stringProp('greyhound-range'),
    raceToGo: arrayProp(),
  },

  data() {
    return {
      STATUS_RACE,
      convertTimeStamp,
      isHidden: true,
    }
  },

  methods: {
    onToRace(id, race) {
      if (
        id.toString() !== this.$route.params.id ||
        race.toString() !== this.$route.params.race
      ) {
        this.$router.push({
          name: 'raceDetail',
          params: { id: id, race: race },
        })
      }
    },

    onToPrevious() {
      window.history.back()
    },
  },
}
</script>
<style lang="scss" scoped>
.nav {
  background: var(--colorBgDark);
  font-weight: 600;
  margin-bottom: 15px;
  color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  overflow: hidden;
  max-height: 270px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  @media (min-width: 960px) {
    display: flex;
    margin-bottom: 0;
    max-height: calc(100vh - 20px);
  }
  .title {
    display: flex;
    align-items: center;
    color: #fff;
    background-color: var(--colorOrange);
    padding: 15px 10px;
    @media (min-width: 960px) {
      padding: 20px 10px;
    }
    span {
      font-size: 0.75rem;
      margin-left: 5px;
      font-weight: 500;
    }
  }

  .back {
    background-color: #555354;
    color: #fff;
    padding: 20px 16px;
    width: 100%;
    display: flex !important;
    align-items: center;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    &:hover {
      filter: brightness(90%);
    }
    .svgIcon {
      width: 15px;
      margin-right: 20px;
      cursor: pointer;
    }
    span {
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  .list-race {
    overflow: scroll;
    display: flex;
    align-items: center;
    @media (min-width: 960px) {
      display: block;
    }
  }
  .item-race {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-right: 15px;
    color: #fff;
    cursor: pointer;
    max-width: 230px;
    &:not(:last-child) {
      border-right: 2px solid #fff;
    }
    @media (min-width: 960px) {
      border-top: 1px solid #828282;
      border-right: 0 !important;
      max-width: 100%;
    }

    .time {
      font-size: 0.75rem;
      font-weight: 500;
      margin-right: 10px;
      width: max-content;
      color: #fff;
      background-color: transparent;
      padding: 0;
    }
    .info {
      font-size: 0.75rem;
      font-weight: 500;
      overflow: hidden;
      flex: 1;
    }
    .info p {
      font-size: 0.75rem;
      margin-left: 3px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .icon {
      display: block;
      @media (min-width: 960px) {
        display: none;
      }
    }
  }
}
</style>
