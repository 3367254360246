import { render, staticRenderFns } from "./RunnerHeader.vue?vue&type=template&id=5b5fa43d&scoped=true&lang=pug&"
import script from "./RunnerHeader.vue?vue&type=script&lang=js&"
export * from "./RunnerHeader.vue?vue&type=script&lang=js&"
import style0 from "./RunnerHeader.vue?vue&type=style&index=0&id=5b5fa43d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5fa43d",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/Users/hiepngo/Code/Appscyclone/play2win/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import BaseDisplayTimer from '@/components/base/BaseDisplayTimer.vue'
autoInstallComponents(component, {BaseDisplayTimer})
