<template lang="pug">
.average-item
  .caption {{ item.event }} R{{ item.eventNo }}
  div
    .caption-sm {{ item.runnerNo }}. {{ item.runnerName }} - Match Odds
    div
      .data-table
        table
          thead
            tr
              th.caption.font-bold Odds
              th.caption.font-bold Stake
              th.caption.font-bold Profit/Liability
          tbody
            tr(:class='{ lay: item.bettype === "LAY" }')
              td.caption {{ item.odds }}
              td.caption {{ item.amount }}
              td.caption {{ Math.round(item.odds * item.amount - item.amount) }}
</template>

<script>
import { objectProp } from '@/helper/props'

export default {
  props: {
    item: objectProp(),
  },
}
</script>

<style lang="scss" scoped>
.caption {
  margin-left: 10px;
  margin-right: 14px;
  margin-top: 8px;
  font-weight: 700;
  color: #000;
}
.caption-sm {
  margin-left: 10px;
  margin-right: 14px;
  margin-top: 4px;
}
.data-table {
  table {
    width: 100%;
    border-spacing: 0;
  }
  table thead th,
  table tbody td {
    color: rgba(0, 0, 0, 0.6);
    height: 32px;
    padding-left: 10px;
    padding-right: 14px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    text-align: left;
    font-weight: 400;
    &:first-child {
      padding-right: 0;
    }
    &.first {
      font-weight: 700;
      width: calc(100vw - 250px);
    }
    &.font-bold {
      font-weight: 700;
    }
  }
  table tbody tr {
    background: var(--colorSecondary);
    border-color: var(--colorSecondary);
    &.lay {
      background: var(--colorThird);
      border-color: var(--colorThird);
    }
  }
  table tbody td {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
