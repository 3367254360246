export const getStyleStake = stake => {
  if (!stake) return

  if (stake === 0) {
    return {
      color: '#000',
    }
  }
  if (stake > 0) {
    return {
      color: '#4caf50',
    }
  }
  if (stake < 0) {
    return {
      color: '#eb2626',
    }
  }
}
