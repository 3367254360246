<template lang="pug">
.row
  .runner
    .title
      p(:class='{ "line-through": item.isScratched }') {{ `${item.runnerNumber}. ${item.outcomeName}` }} <span>({{ item.barrier }})</span>
      span {{ item.jockey }} / {{ item.trainer }}
    template(v-if='getProfit')
      .profit
        p(:style="getProfit.styles") {{ getProfit.value }}
    template(v-if='!item.isScratched')
      .list-item
        .overlay(v-if='handleBetNotOpen')
        template(v-if='!isNaN(item.backPrice)')
          .left(
            @click='handleAddBet(item.backPrice, "BACK")',
            :class='{ active: checkShowActive("BACK") }'
          ) {{ item.backPrice | formatPrice }}
          .right(
            @click='handleAddBet(item.layPrice, "LAY")',
            :class='{ active: checkShowActive("LAY") }'
          ) {{ item.layPrice | formatPrice }}
        template(v-if='!item.backPrice')
          .right.sp(
            @click='handleAddBet(1.0, "SP")',
            :class='{ active: checkShowActive("SP") }'
          ) SP
    template(v-else)
      .list-item-scratched
        p.scrat Scratched
        p {{ time | formatDate2("HH:mm MM/dd/yy") }}
    //- Modal
    BaseModal(:open='open', @click='open = false')
      p.textModal.text-left {{ message }}
      .btnModal
        BaseButton(@click='open = false', type='orange') Cancel
    //- End Modal
  BetSlip(v-if='checkShowBetSlip')
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { arrayProp, objectProp, numberProp, stringProp } from '@/helper/props'
import { countDownTimer } from '@/helper/count-down-timer.js'
import { STATUS_RACE, TYPE_BET } from '@/assets/constants'
import BetSlip from '../../betslip/partial/BetSlip'
import { getStyleStake } from '@/helper/get-style-stake.js'

export default {
  components: {
    BetSlip,
  },

  props: {
    runners: arrayProp(),
    item: objectProp(),
    i: numberProp(),
    rs: stringProp(),
    time: stringProp(),
    mn: stringProp(),
    mid: stringProp(),
    rt: stringProp(),
    status: stringProp(),
    eventNo: stringProp(),
  },

  data() {
    return {
      open: false,
      message: 'Betting closed.',
      screenWidth: screen.availWidth,
    }
  },

  computed: {
    ...mapGetters('bet', ['listBet']),
    ...mapState('betting/openBet', {
      loading: 'loading',
      dataOpenBet: 'data',
    }),
    ...mapState('raceDetail', ['loading', 'data']),

    convertBets() {
      if (!this.dataOpenBet.length) return

      const race = this.dataOpenBet.find(item => {
        return item.betData.find(
          i2 => i2.marketId === this.mid && i2.eventNo === this.eventNo
        )
      })

      if (!race || this.item.isScratched || !this.item.backPrice) return

      const raceBets = race.betData
        .filter(bet => bet.eventNo === this.eventNo)
        .map(bet => {
          const profit = Math.round(bet.odds * bet.stake - bet.stake)
          return {
            ...bet,
            profit: bet.type === TYPE_BET.LAY ? -profit : profit,
            sameBet: false,
          }
        })

      return raceBets
    },

    getStake() {
      if (!this.convertBets || !this.convertBets.length) return
      const raceBets = this.convertBets

      const stakeGroup = raceBets.reduce(
        (stake, bet) => {
          if (bet.type === TYPE_BET.BACK) {
            stake.totalBack += bet.stake
          }
          if (bet.type === TYPE_BET.LAY) {
            stake.totalLay += bet.stake
          }

          stake.totalBL = stake.totalBack + stake.totalLay
          stake.total = stake.totalLay - stake.totalBack
          return stake
        },
        {
          totalBack: 0,
          totalLay: 0,
          total: 0,
          totalBL: 0,
        }
      )
      return stakeGroup
    },

    getProfit() {
      if (!this.convertBets || !this.convertBets.length) return
      const raceBets = this.convertBets
      const stakeGroup = this.getStake

      const mergeRunners = raceBets.slice().reduce((runners, bet) => {
        const index = runners.reverse().findIndex(rn => {
          return (
            rn.runnerNo === bet.runnerNo && rn.runnerName === bet.runnerName
          )
        })
        if (index === -1) {
          runners.push(bet)
        } else {
          runners[index].sameBet = true
          runners[index].profit += bet.profit
          runners[index].stake += bet.stake
        }
        return runners
      }, [])

      const bet = mergeRunners.find(
        item =>
          item.runnerNo === this.item.runnerNumber &&
          item.runnerName === this.item.outcomeName
      )

      if (!bet) {
        const styles = getStyleStake(stakeGroup.total)
        return {
          styles,
          value: `${stakeGroup.total}`,
        }
      }

      if (bet.sameBet) {
        const result = bet.profit - (stakeGroup.totalBL - bet.stake)
        const styles = getStyleStake(result)

        return {
          styles,
          value: result,
        }
      }

      if (bet.type === TYPE_BET.LAY) {
        const result =
          bet.profit - stakeGroup.totalBack + (stakeGroup.totalLay - bet.stake)
        const styles = getStyleStake(result)

        return {
          styles,
          value: result,
        }
      }

      const result =
        bet.profit - (stakeGroup.totalBack - stakeGroup.totalLay - bet.stake)
      const styles = getStyleStake(result)
      return {
        styles,
        value: result,
      }
    },

    checkShowBetSlip() {
      const findBet = this.listBet.some(
        item =>
          item.meetingId === this.mid &&
          item.runnerNumber === this.item.runnerNumber
      )
      if (this.screenWidth < 960 && this.listBet.length > 0 && findBet) {
        return true
      }
      return false
    },

    handleBetNotOpen() {
      if (!this.data.startTime) return
      const timer = countDownTimer(this.data.startTime)
      const isDisabled = this.runners.some(
        runner => !runner.backPrice && !runner.isScratched
      )
      return timer.type === 'minutes' || timer.type === 'hours' || isDisabled
    },
  },

  methods: {
    checkShowActive(type) {
      return this.listBet.some(bet => {
        return (
          bet.meetingId === this.mid &&
          bet.betType === type &&
          bet.rb === this.item.barrier &&
          bet.runnerNumber === this.item.runnerNumber
        )
      })
    },

    handleAddBet(odds, type) {
      const status = this.status.toUpperCase()
      const timer = countDownTimer(this.time)
      if (timer.type === 'minutes' || timer.type === 'hours') return
      if (status === STATUS_RACE.ABANDONED) {
        this.message = 'This race has been abandoned.'
        this.open = true
        return
      }
      if (status !== STATUS_RACE.OPEN && timer.type === 'time_out') {
        this.message = 'Betting for this race is closed.'
        this.open = true
        return
      }
      if (!odds) return
      const item = {
        runnerNumber: this.item.runnerNumber, // runner number
        outcomeName: this.item.outcomeName, // runner name
        eventNumber: this.eventNo,
        meetingName: this.mn, // meeting name,
        meetingId: this.mid, // meeting Id
        rb: this.item.barrier, // barrier runner
        rt: this.rt, // race type ['GREY', 'HARN', 'HORS']
        em: '', // default error msg
        stake: '', // stake
        odds: odds, // odds
        betType: type, // type bet
        timeOfRace: this.time, // time race
        status: this.status,
      }
      if (!this.checkShowActive(item.betType)) this.doAddItemToBet(item)
      else this.doRemoveBetByInfo(item)
    },

    ...mapActions({
      doAddItemToBet: 'bet/addItemToBet',
      doRemoveBetByInfo: 'bet/removeBetByInfo',
    }),
  },
}
</script>

<style lang="scss" scoped>
.row {
  &:nth-child(2) > .runner {
    margin-top: 0;
    border-top: 1px solid #e0e0e0;
  }
  .runner {
    display: flex;
    align-items: center;
    background: white;
    padding: 8px 15px;
    margin-top: 5px;
    @media (min-width: 960px) {
      padding: 8px 24px;
    }
  }

  .runner .title {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-right: 10px;
    @include textEllipsis;
    span {
      font-weight: 400;
      font-size: 11px;
    }
  }

  .runner .profit {
    padding: 0 15px;
    font-weight: 600;
  }

  .runner .list-item {
    display: flex;
    align-items: center;
    user-select: none;
    position: relative;
    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 60px;
      height: 100%;
      padding: 8px;
      font-weight: 700;
      font-size: 12px;
      place-self: center;
      cursor: pointer;
    }
    .left,
    .right {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        inset: 0;
        opacity: 0;
        color: inherit;
        background-color: currentColor;
        border-radius: inherit;
        pointer-events: none;
        transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      }
      &:hover:before {
        opacity: 0.08;
      }
      &.active {
        background: var(--gradientOrange);
        color: #fff;
      }
    }
    .left {
      background: var(--colorSecondary);
      margin-right: 2px;
    }
    .right {
      background: var(--colorThird);
    }
    .sp {
      grid-column-start: 2;
      position: relative;
      z-index: 10;
      background-color: #e1e1e1;
      pointer-events: none;
      color: #00000042;
    }
    .overlay {
      height: 100%;
      position: absolute;
      inset: 0;
      width: 100%;
      opacity: 0.35;
      background-color: #212121;
      border-color: #212121;
      z-index: 6;
      cursor: auto;
      transition: opacity cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  .runner .list-item-scratched {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 120px;
    p {
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: #000;
    }
    .scrat {
      font-weight: 700;
    }
  }

  .line-through {
    text-decoration: line-through;
  }
}
</style>
