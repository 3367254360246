import { render, staticRenderFns } from "./VideoSection.vue?vue&type=template&id=49151b44&scoped=true&lang=pug&"
import script from "./VideoSection.vue?vue&type=script&lang=js&"
export * from "./VideoSection.vue?vue&type=script&lang=js&"
import style0 from "./VideoSection.vue?vue&type=style&index=0&id=49151b44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49151b44",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/Users/hiepngo/Code/Appscyclone/play2win/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import BaseButton from '@/components/base/BaseButton.vue'
autoInstallComponents(component, {BaseButton})
