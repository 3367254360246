import api from '@/plugins/api'
import { getTimeStamp } from '@/helper/get-time-stamp'

const state = () => ({
  loading: false,
  success: false,
})

const mutations = {
  LOADING: s => Object.assign(s, state(), { loading: true }),
  LOADED: state => (state.loading = false),
  SET_SUCCESS: (s, payload) => (s.success = payload),
  RESET: s => Object.assign(s, state()),
}

const actions = {
  async quickBetAsync({ commit, dispatch }, payload) {
    const time = getTimeStamp()
    commit('LOADING')
    try {
      const res = await api.post(`b/BetRequest?rand=${time}`, payload)
      if (res.data.err) {
        console.log(res.data.err)
      } else {
        // check result singles bet
        if (res.data.brl.length > 0) {
          commit('SET_SUCCESS', true)
          dispatch('betting/transStatus/transStatusAsync', res.data.brl, {
            root: true,
          })
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      commit('LOADED')
    }
  },

  reset({ commit }) {
    commit('RESET')
  },
}

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
}
