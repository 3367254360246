export const TYPE_RACE = {
  GREY: 'Greyhounds',
  HARN: 'Harness',
  HORS: 'Horse Racing',
}

export const TYPE_RACE_CARD = {
  GREY: 'G',
  HARN: 'H',
  HORS: 'T',
}

export const STATUS_RACING = {
  CLOSED: 'CLSD',
  FINAL: 'FINAL',
  ABANDONED: 'ABND',
  INTERIM: 'INTERIM',
  OPEN: 'OPEN',
  // DONE: '',
}

export const STATUS_RACE = {
  CLOSED: 'CLOSED',
  DONE: 'DONE',
  FINAL: 'FINAL',
  ABANDONED: 'ABANDONED',
  INTERIM: 'INTERIM',
  SKIP: 'SKIP',
  OPEN: 'OPEN',
}

export const TYPE_TIMER = {
  HOURS: 'hours',
  MINUTES: 'minutes',
  TIMEOUT: 'time_out',
}

export const TITLE_BET = {
  BETSLIP: 'betSlip',
  OPENBET: 'openBet',
}

export const TYPE_BET = {
  LAY: 'LAY',
  BACK: 'BACK',
}
