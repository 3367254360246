import { format, parseISO } from 'date-fns'
import api from '@/plugins/api'
import { currentDay } from '@/helper/current-day.js'

const state = () => ({ data: '', loading: false, error: '' })

const getters = {}

const mutations = {
  LOADING: s => (s.loading = true),
  LOADED: state => (state.loading = false),
  SET_DATA: (s, data) => (s.data = data),
  SET_ERROR: (s, error) => (s.error = error),
  RESET: s => Object.assign(s, state()),
}

const actions = {
  async getAsync({ commit }) {
    commit('LOADING')
    const { date, month, fullYear } = currentDay()
    try {
      const { data } = await api.get(
        `events/short/${fullYear}-${month}-${date}`
      )
      if (!data || !data.meeting) {
        console.log('get api fail...')
        return
      }
      data.meeting = data.meeting.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            t =>
              t.meetingName === thing.meetingName &&
              t.country === thing.country &&
              t.sportCode === thing.sportCode
          )
      )

      const mapData = data.meeting.reduce((r, a) => {
        a.races = a.races.map(el => {
          const startTime = new Date(
            `${format(parseISO(el.startTime), 'MM/dd/yyy hh:mm:ss a')} UTC`
          ).toString()
          const timeCurrent = new Date(new Date().toISOString())

          const distanceTime = Math.floor(
            (new Date(startTime) - new Date(timeCurrent)) / 1000
          )
          const rs = el.result ? 'Final' : distanceTime < 0 ? 'Closed' : 'Open'

          return { ...el, startTime: startTime, rs }
        })

        r[a.sportCode] = [...(r[a.sportCode] || []), a]
        return r
      }, {})
      commit('SET_DATA', mapData)
      return mapData
    } catch (error) {
      console.log(error)
    } finally {
      commit('LOADED')
    }
  },
  reset({ commit }) {
    commit('RESET')
  },
}

export default {
  namespaced: true,
  state: state,
  getters,
  mutations,
  actions,
}
