import { render, staticRenderFns } from "./BaseButton.vue?vue&type=template&id=6a7891c2&scoped=true&lang=pug&"
import script from "./BaseButton.vue?vue&type=script&lang=js&"
export * from "./BaseButton.vue?vue&type=script&lang=js&"
import style0 from "./BaseButton.vue?vue&type=style&index=0&id=6a7891c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a7891c2",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/Users/hiepngo/Code/Appscyclone/play2win/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import BaseLoadingSpinner from '@/components/base/BaseLoadingSpinner.vue'
autoInstallComponents(component, {BaseLoadingSpinner})
