<template lang="pug">
.data-item
  div
    .caption {{ itemInId.event }}
    .data-table
      table
        thead
          tr
            th.caption.first Match Odds
            th.caption Odds
            th.caption Stake
            th.caption Profit/Liability
        tbody
          tr(
            v-for='(item, key) in betData',
            :key='key',
            :class='{ lay: item.bettype === "LAY" }'
          )
            td.caption {{ item.runnerNo }}. {{ item.horse }}
            td.caption {{ item.odds }}
            td.caption {{ item.amount }}
            td.caption {{ Math.round(item.odds * item.amount - item.amount) }}
</div>
</template>

<script>
import { arrayProp, objectProp } from '@/helper/props'
export default {
  props: {
    betData: arrayProp(),
    itemInId: objectProp(),
  },
}
</script>

<style lang="scss" scoped>
.caption {
  margin-left: 10px;
  margin-right: 14px;
  margin-top: 8px;
  font-weight: 700;
  color: #000;
}
.data-table {
  table {
    width: 100%;
    border-spacing: 0;
  }
  table thead th,
  table tbody td {
    color: rgba(0, 0, 0, 0.6);
    height: 32px;
    padding-left: 10px;
    padding-right: 12px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    text-align: left;
    font-weight: 400;
    &:first-child {
      padding-right: 0;
    }
    &.first {
      font-weight: 700;
      width: calc(100vw - 250px);
    }
    &.font-bold {
      font-weight: 700;
    }
  }
  table tbody tr {
    background: var(--colorSecondary);
    border-color: var(--colorSecondary);
    &.lay {
      background: var(--colorThird);
      border-color: var(--colorThird);
    }
  }
  table tbody td {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
