<template lang="pug">
.container
  NavBar.c1(:raceToGo='getRaceToGo')
  .main.c2
    template
      RunnerHeader(:raceTogo='getRaceToGo')
      template(v-if='convertRunnerResult.length > 0 && !loadingDetail')
        RunnerResult(
          v-for='(result, index) in convertRunnerResult',
          :item='result',
          :key='index',
          :index='index'
        )
      RunnerControl
    template
      RunnerUpcoming(:rn-upcoming='getRaceToGo')
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { stringProp, objectProp } from '@/helper/props'
import RunnerHeader from './partial/RunnerHeader'
import RunnerResult from './partial/RunnerResult'
import RunnerControl from './partial/RunnerControl'
import RunnerUpcoming from './partial/RunnerUpcoming'
import NavBar from '@/components/layout/NavBar.vue'
export default {
  components: {
    NavBar,
    RunnerHeader,
    RunnerResult,
    RunnerControl,
    RunnerUpcoming,
  },

  props: {
    type: stringProp(),
    listRacing: objectProp(),
  },

  data() {
    return {
      autoRefreshNextTogo: '',
    }
  },

  computed: {
    ...mapState('racing/list', {
      loadingRacing: 'loading',
    }),

    ...mapState('raceDetail', {
      raceDetail: 'data',
      loadingDetail: 'loading',
    }),

    getRaceToGo() {
      const raceToGo = this.listRacing[this.type].reduce((rs, item) => {
        item.races.map(race => {
          const timeCurrent = new Date(new Date().toISOString())
          const distanceTime = Math.floor(
            (new Date(race.startTime) - new Date(timeCurrent)) / 1000
          )

          if (race.status !== 'OPEN') return
          const mapData = {
            ...race,
            rjs: distanceTime,
            meetingId: item.meetingId,
            meetingName: item.meetingName,
            country: item.country,
          }
          rs.push(mapData)
        })
        return rs
      }, [])

      raceToGo.sort((a, b) => a.rjs - b.rjs)
      return raceToGo
    },

    convertRunnerResult() {
      const arrRunner = []
      if (this.raceDetail && this.raceDetail.result) {
        this.raceDetail.result.split(',').forEach((item, index) => {
          if (item.includes('/')) {
            const tempItem = item.split('/')
            tempItem.forEach(i =>
              arrRunner.push({ runnerNumber: i, rank: index + 1 })
            )
          } else arrRunner.push({ runnerNumber: item, rank: ++index })
        })
      }
      const itemRunner = []
      arrRunner.forEach(item => {
        const runner = this.raceDetail.runners.find(
          i => i.runnerNumber === item.runnerNumber
        )
        if (runner && item.rank === 1)
          itemRunner.push({ ...runner, rank: item.rank })
      })
      return itemRunner
    },
  },

  created() {
    this.autoRefreshNextTogo = setInterval(this.fetchRacingList, 10000)
  },

  destroyed() {
    clearInterval(this.autoRefreshNextTogo)
  },

  methods: {
    async fetchRacingList() {
      await this.getRacingList()
    },

    ...mapActions({
      getRacingList: 'racing/list/getAsync',
    }),
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: block;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  @media (min-width: 960px) {
    display: grid;
    gap: 0 15px;
    grid: 'c1 c2'/1.7fr 4fr;
  }
}
</style>
