<template lang="pug">
img.svgIcon(:src='require(`@/assets/svg/${name}.svg`)', @click='$emit("click")')
</template>

<script>
import { stringProp } from '@/helper/props'
export default {
  props: {
    name: stringProp('greyhound-range'),
  },
}
</script>
<style lang="scss" scoped>
.svgIcon {
  width: 40px;
  height: auto;
}
</style>
