import api from '@/plugins/api'
import { getTimeStamp } from '@/helper/get-time-stamp'

const state = () => ({ loading: false })

const getters = {}

const mutations = {
  LOADING: s => (s.loading = true),
  LOADED: state => (state.loading = false),
}

const actions = {
  async getAsync({ commit }, { when, rt }) {
    commit('LOADING')
    const time = getTimeStamp()
    try {
      const result = await api.get(`n/GetRaceCard/${when}/${rt}?rand=${time}`)
      return result.data.fm
    } catch (error) {
      console.log(error)
    } finally {
      commit('LOADED')
    }
  },
  async getEventRaceAsync({ commit }, { id }) {
    commit('LOADING')
    const time = getTimeStamp()
    try {
      const { data } = await api.get(`b/GetEventRace/${id}?rand=${time}`)
      return data
    } catch (err) {
      console.log(err)
    } finally {
      commit('LOADED')
    }
  },
}

export default {
  namespaced: true,
  state: state,
  getters,
  mutations,
  actions,
}
