import { intervalToDuration } from 'date-fns'

export const countDownTimer = time => {
  const now = new Date()
  const timeStamp = new Date(time)
  const { hours, minutes, seconds } = intervalToDuration({
    start: now,
    end: timeStamp,
  })

  const raceHours = timeStamp.getHours()
  const raceMinutes = String(timeStamp.getMinutes()).padStart(2, '0')
  const cvMinutes = String(minutes).padStart(2, '0')
  if (timeStamp - now <= 0) {
    return {
      display: `-${minutes >= 1 ? `${minutes}m ${seconds}s` : `${seconds}s`}`,
      type: 'time_out',
    }
  }

  if (hours >= 1) {
    return {
      display: `${raceHours}:${raceMinutes}`,
      type: 'hours',
    }
  }

  if (minutes > 10) {
    return { display: `${parseInt(cvMinutes)}m `, type: 'minutes' }
  }

  if (minutes >= 5 && minutes <= 10) {
    return { display: `${parseInt(cvMinutes)}m `, type: 'normal' }
  }

  if (minutes === 0) {
    return {
      display: `${seconds}s`,
      type: minutes < 5 ? 'waiting_start' : 'normal',
    }
  }

  return {
    display: `${minutes < 5 ? `${parseInt(minutes)}m` : ''} ${seconds}s`,
    type: minutes < 5 ? 'waiting_start' : 'normal',
  }
}
