<template lang="pug">
.input-container(:class='{ "error-text": validation.$error }')
  input.base-input-stake(
    ref='inputStake',
    :value='currentInput',
    :placeholder='placeholder',
    :type='type',
    :min='min',
    :max='max',
    :readonly='readonly',
    :disabled='disabled',
    @input='onInput',
    @blur='$emit("blur")'
  )
  span.label(v-if='label') {{ label }}
  BaseValidation(:name='name', :data='validation')
</template>

<script>
import { stringProp, booleanProp, objectProp } from '@/helper/props'

export default {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: { type: [Number, String], default: '' },
    placeholder: stringProp(), // placeholder of text exp: name
    label: stringProp(), // label of input
    type: stringProp(), // type of input number, string,...
    min: stringProp(), // min of number value
    max: stringProp(), // max of number value
    disabled: booleanProp(),
    readonly: booleanProp(),
    validation: objectProp(),
    name: stringProp(),
  },
  data() {
    return {
      currentInput: '',
    }
  },
  watch: {
    value(val) {
      this.currentInput = val
      if (this.name === 'Odds') {
        this.currentInput = val.toFixed(2)
      }
    },
  },
  created() {
    if (isNaN(this.val) || !this.val) this.currentInput = this.value
    if (this.name === 'Odds') {
      this.currentInput = this.value.toFixed(2)
    }
  },

  methods: {
    onInput(e) {
      const val = e.target.value
      if (isNaN(val) || !val) {
        this.$emit('input', '')
        return
      }
      this.$emit('input', val)
    },
  },
}
</script>
<style lang="scss" scoped>
.input-container {
  position: relative;
  font-size: 12px;
  .label {
    position: absolute;
    left: 12px;
    top: -12px;
    padding: 0 8px;
    font-size: 11px;
    color: #000;
    background: white;
  }
  .base-input-stake {
    width: 100%;
    padding: 10px;
    color: #000;
    font-size: 12px;
    border-radius: 4px;
    background-color: #fff;
    border: 2px solid #979797;
    &:focus + .label {
      color: var(--colorOrange);
    }
    &:focus {
      border: 2px solid var(--colorOrange);
    }
    &::placeholder {
      color: #b0b0b0;
    }
    &::before {
      content: 'Read this: ';
    }
  }
}

.error-text {
  color: var(--colorError) !important;
  font-size: 12px;
  font-weight: 400;
  transition: all 0.25s ease;
  .error-validate {
    opacity: 1;
    transform: translateY(15%);
    transition: transform 0.25s linear, opacity 0.25s linear;
  }
  .label {
    color: var(--colorError) !important;
    animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .baseInputStake {
    border: 2px solid var(--colorError) !important;
    transition: border 0.25s ease;
  }
}
</style>
