<template lang="pug">
button(
  :class='classes',
  :disabled='disabled',
  :tabIndex='tabIndex',
  v-on='$listeners'
)
  template(v-if='loading')
    BaseLoadingSpinner
  template(v-else)
    slot
</template>

<script>
import { stringProp, booleanProp } from '@/helper/props'
export default {
  name: 'BaseButton',
  props: {
    loading: booleanProp(),
    disabled: booleanProp(),
    block: booleanProp(),
    tabIndex: stringProp(),
    type: stringProp('default'),
  },
  computed: {
    classes() {
      return {
        btn: true,
        [`btn-${this.type}`]: true,
        block: !!this.block,
        disabled: !!this.disabled,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn:not(:disabled) {
  @include btnCustom(12px, 8px 13px, 4px, #000);
  @include buttonStyle;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0;
    color: inherit;
    background-color: currentColor;
    border-radius: inherit;
    pointer-events: none;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  &:hover:before {
    opacity: 0.08;
  }
}

.btn-primary {
  @include btnType(#d8d8d8);
}

.btn-orange {
  @include btnType(var(--gradientOrange));
  color: #fff !important;
}

.btn-green {
  @include btnCustom(12px, 8px 19px, 4px, #fff !important);
  @include btnType(#3ab136);
  display: flex;
  margin-left: auto;
}

.disabled {
  cursor: not-allowed !important;
  background: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
</style>
