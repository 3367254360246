<template lang="pug">
.control
  BetHeader(
    @clickBetSlip='setActiveTab(TITLE_BET.BETSLIP)',
    @clickOpenBet='setActiveTab(TITLE_BET.OPENBET)'
  )
  template(v-if='screenWidth >= 960 || dataActiveTabs === TITLE_BET.BETSLIP')
    .control-selector
      p.title Race Odds
      .right
        template(v-if='checkBackLay')
          p Back
          p Lay
        template(v-else)
          p SP
    template(v-if='loading || checkShowData')
      .loader
    template(v-else)
      RunnerItem(
        v-for='(runner, i) in data.runners',
        :key='i',
        :runners='data.runners',
        :item='runner',
        :i='i',
        :rs='data.rs',
        :status='data.status',
        :time='data.startTime',
        :mid='data.meetingId',
        :mn='data.meetingName',
        :rt='data.sportCode',
        :eventNo='data.eventNumber'
      )
  template(v-show='dataActiveTabs === TITLE_BET.OPENBET')
    OpenBet(v-if='screenWidth < 960')
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { STATUS_RACE } from '@/assets/constants'
import { TITLE_BET } from '@/assets/constants'
import RunnerItem from './RunnerItem'
import BetHeader from '@/components/block/betslip/partial/BetHeader.vue'
import OpenBet from '@/components/block/betslip/partial/OpenBet.vue'
export default {
  components: {
    RunnerItem,
    BetHeader,
    OpenBet,
  },

  data() {
    return {
      TITLE_BET,
      screenWidth: screen.availWidth,
    }
  },

  computed: {
    ...mapState('raceDetail', {
      loading: 'loading',
      data: 'data',
    }),

    ...mapState('activeTabs', {
      dataActiveTabs: 'data',
    }),

    checkBackLay() {
      if (!this.data.runners) return false
      const backPrice = this.data.runners.some(
        i => i.backPrice && !isNaN(i.backPrice)
      )
      const layPrice = this.data.runners.some(
        i => i.layPrice && !isNaN(i.layPrice)
      )
      if (backPrice && layPrice) return true
      return false
    },

    checkShowData() {
      if (
        !this.data ||
        this.data.status === STATUS_RACE.SKIP ||
        (!this.data.result && this.data.status === STATUS_RACE.DONE)
      )
        return true
      return false
    },
  },

  methods: {
    ...mapActions({
      setActiveTab: 'activeTabs/setActiveTab',
    }),
  },
}
</script>

<style lang="scss" scoped>
.control {
  position: relative;
  margin-top: 65px;
  @media (min-width: 960px) {
    margin-top: 0;
  }
}
.control-selector {
  display: flex;
  align-items: center;
  padding: 2px 15px;
  font-size: 0.75rem;
  background-color: #e1dbd8;
  @media (min-width: 960px) {
    padding: 2px 24px;
  }
  .title {
    flex: 1;
  }
  .right {
    display: flex;
    align-items: center;
    p:first-child {
      margin-right: 2px;
    }
    p {
      font-size: 0.75rem;
      line-height: 1.25rem;
      text-align: center;
      font-weight: 700;
      min-width: 60px;
    }
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.loader {
  position: relative;
  top: 10px;
  left: 50%;
  font-size: 5px;
  text-indent: -9999em;
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #ffffff;
  transform: translateX(-50%);
  animation: load 1.1s infinite linear;
  z-index: 25;
}
</style>
