import router from '@/router/index.js'
import _ from 'lodash'

const state = () => ({
  accessToken: '',
  refreshToken: '',
  userIp: '127.0.0.1',
  userId: '',
  sid: '',
  multiplier: '',
})

const getters = {
  isLoggedIn: s => s.accessToken !== '',
}

const mutations = {
  SET_TOKEN: (s, token) => (s.accessToken = token),
  SET_USER_IP: (s, ip) => (s.userIp = ip),
  SET_USER: (s, user) => (s.user = user),
  SET_USERID: (s, userId) => (s.userId = userId),
  SET_MULTIPLIER: (s, multiplier) => (s.multiplier = multiplier),
  SET_SID: (s, sid) => (s.sid = sid),
  RESET: s => Object.assign(s, state()),
}

const actions = {
  async getInfoUser({ commit }) {
    try {
      const query = router.history.current.query
      const { sid, userId, accessToken, multiplier } = query

      if (_.isEmpty(query)) return
      commit('SET_SID', sid)
      commit('SET_USERID', userId)
      commit('SET_TOKEN', accessToken.replace('/', ''))
      commit('SET_MULTIPLIER', multiplier.replace(/[^a-zA-Z0-9]/g, ''))
    } catch (error) {
      console.log(error)
    }
  },
}

export default {
  namespaced: true,
  state: state,
  getters,
  mutations,
  actions,
}
