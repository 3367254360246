<template lang="pug">
div
  VideoSection(v-if='isActive')
  .race
    .race-header
      .info
        .title(v-if='data')
          h4 {{ data.meetingName }} <span>({{ data.country }})</span>
          p R{{ data.raceNumber }} {{ data.name }}
        span.time(v-if='!isOpen && isStatusRace')
          template(v-if='isStatusRace === STATUS_RACE.OPEN') {{ timeDisplay }}
          template(v-else) {{ isStatusRace }}
        template(v-else-if='data.startTime')
          BaseDisplayTimer.time(
            :time='data.startTime',
            @close='isOpen = false'
          )
      .badge-time(v-if='handleShowBadge && handleShowBadge.show')
          span {{ handleShowBadge.note}}
    //- .action
    //-   BaseButton.btn-video(@click='isActive = !isActive')
    //-     BaseIcon.icon(v-if='isActive', name='invisible')
    //-     BaseIcon.icon(v-else, name='visibility')
    //-     span Watch Live
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { arrayProp } from '@/helper/props'
import { STATUS_RACE } from '@/assets/constants'
import { countDownTimer } from '@/helper/count-down-timer.js'
import VideoSection from './VideoSection'

export default {
  components: { VideoSection },

  props: {
    raceTogo: arrayProp(),
  },

  data() {
    return {
      STATUS_RACE,
      isActive: false,
      isOpen: true,
      autoRefreshTimer: '',
      timeDisplay: '--',
      isStatusRace: '-',
    }
  },

  computed: {
    ...mapState('raceDetail', ['loading', 'data']),

    handleShowBadge() {
      if (!this.data) return
      const isSP = this.data.runners.some(
        runner => !runner.backPrice && !runner.isScratched
      )

      if (isSP) {
        return {
          show: isSP,
          note: 'Odds for race is not available at this time',
        }
      }

      if (!this.data.startTime) return
      const timer = countDownTimer(this.data.startTime)
      return {
        show: timer.type === 'hours' || timer.type === 'minutes',
        note: 'Betting from 10mins before start',
      }
    },
  },

  watch: {
    data(val) {
      const status = val.status
      this.isStatusRace = status
      if (status === STATUS_RACE.DONE) this.isStatusRace = STATUS_RACE.FINAL
      if (
        status === STATUS_RACE.ABANDONED ||
        ((status === STATUS_RACE.INTERIM ||
          status === STATUS_RACE.FINAL ||
          status === STATUS_RACE.DONE) &&
          val.result)
      )
        this.resetAutoRequest()
    },

    '$route.params': {
      handler(value) {
        this.getDetailAsync({
          id: value.id || this.raceTogo[0].meetingId,
          race: value.race || this.raceTogo[0].raceNumber,
          loading: true,
        })
      },
    },
  },

  destroyed() {
    this.resetAutoRequest()
  },

  async created() {
    if (!this.$route.params.id && !this.raceTogo.length) return
    await this.getDetailAsync({
      id: this.$route.params.id || this.raceTogo[0].meetingId,
      loading: true,
      race: this.$route.params.race || this.raceTogo[0].raceNumber,
    })
    if (!this.data) return
    this.autoRefreshTimer = setInterval(this.handleCountDown, 3000)
    setInterval(this.countDownTimerOpen, 1000)
  },

  methods: {
    resetAutoRequest() {
      clearInterval(this.autoRefreshTimer)
      window.clearInterval(this.handleCountDown)
      window.clearInterval(this.countDownTimerOpen)
    },

    onToHome() {
      this.$router.push({
        name: 'Home',
        params: '/',
      })
    },

    handleCountDown() {
      this.getDetailAsync({
        id: this.$route.params.id || this.raceTogo[0].meetingId,
        race: this.$route.params.race || this.raceTogo[0].raceNumber,
      })
    },

    countDownTimerOpen() {
      const timeOpen = this.data.startTime
      const status = this.data.status
      if (status && status === STATUS_RACE.OPEN)
        this.timeDisplay = countDownTimer(timeOpen).display
    },

    ...mapActions({
      getDetailAsync: 'raceDetail/detailAsync',
    }),
  },
}
</script>

<style lang="scss" scoped>
.race {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: 10px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  .race-header {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .race-header .info {
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 90%;
    min-height: 50px;
    max-width: 90%;
    padding: 8px;
    background: linear-gradient(
      180deg,
      var(--colorOrange) 0%,
      var(--colorOrange2)
    );
    &:before {
      content: '';
      -webkit-clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
      clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
      width: 15px;
      height: 100%;
      background: linear-gradient(
        180deg,
        var(--colorOrange) 0%,
        var(--colorOrange2)
      );
      position: absolute;
      right: -14px;
      top: 0;
    }
    @media screen and (min-width: 576px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
  }

  .race-header .info .title h4 {
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 700;
    max-width: 100%;
  }

  .race-header .info .title span {
    display: inline-block;
    margin-left: 2px;
  }

  .race-header .info .time {
    display: block;
    max-width: 30%;
    width: 30%;
    text-align: right;
    font-weight: 700;
    font-size: 0.688rem;
    line-height: 1.25rem;
    margin-left: 5px;
    margin-right: 15px;
    background: transparent;
    color: #fff;
    @media (min-width: 960px) {
      margin-right: 8px;
    }
  }

  .race-header .badge-time {
    position: absolute;
    top: -14px;
    right: 0;
    padding: 0 10px;
    border-radius: 2px;
    background: var(--colorError);
    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    @media (min-width: 576px) {
      position: relative;
      top: 0;
      max-width: 150px;
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-weight: 700;
      background-color: transparent;
      box-shadow: none;
    }
    span {
      color: #fff;
    }
  }

  .action {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    justify-content: flex-end;
    @media (min-width: 576px) {
      flex: 0 1 auto;
    }
  }

  .action .btn-video {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #fff;
    padding: 0;
    font-size: 0.688rem;
    &:hover:before {
      opacity: 0;
    }
  }
  .action .btn-video .icon {
    width: 20px;
    margin-right: 10px;
  }
}
</style>
