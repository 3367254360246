<template lang="pug">
.switchBtnControl
  .switchBtn(:class='{ enabled: value }', @click='toggle')
    .btn
</template>

<script>
import { booleanProp } from '@/helper/props'

export default {
  name: 'BaseSwitch',
  props: {
    value: booleanProp(),
  },

  methods: {
    toggle() {
      this.$emit('input', !this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.switchBtnControl {
  .switchBtn {
    width: 48px;
    height: 25px;
    border: 1px solid transparent;
    border-radius: 24px;
    cursor: pointer;
    user-select: none;
    background-color: #d9d9d9;
    transition: all 0.3s ease-in-out;
    &:hover .btn:before {
      background: currentColor;
      transform: scale(2.4);
      transition: none;
    }
    &.enabled {
      background-color: #c6e6c7;
      box-shadow: none;
      .btn {
        background-color: #4caf50;
        transform: translateX(22px);
      }
      &:hover > .btn:before {
        background-color: #4caf50;
      }
    }
    .btn {
      position: relative;
      top: 2px;
      left: 3px;
      width: 19px;
      height: 19px;
      border: 1px solid transparent;
      border-radius: 50%;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
      &:before {
        content: '';
        position: absolute;
        inset: 0;
        opacity: 0.2;
        border-radius: inherit;
        transition: inherit;
        transform: scale(0.2);
        transform-origin: center center;
      }
    }
  }
}
</style>
