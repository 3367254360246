<template lang="pug">
.upcoming(v-if="listUpcoming.length")
  .race
    .info
      .title Upcoming Races
  .row
    .runner(
      v-for='(race, i) in listUpcoming',
      @click='onToRace(race.meetingId, race.raceNumber)'
    )
      .name R{{ race.raceNumber }} {{ race.meetingName }} ({{ race.country }})
      BaseIcon.icon(name='arrow-right')
</template>

<script>
import { arrayProp } from '@/helper/props'
export default {
  props: {
    rnUpcoming: arrayProp(),
  },
  computed: {
    listUpcoming() {
      if (!this.rnUpcoming.length) return []
      const mettingId = this.$route.params.id || this.rnUpcoming[0].meetingId
      const raceNumber =
        this.$route.params.race || this.rnUpcoming[0].raceNumber

      const filerRunners = this.rnUpcoming.filter(rn => {
        if (rn.meetingId === mettingId && rn.raceNumber === raceNumber) return
        return rn
      })
      return filerRunners.slice(0, 3)
    },
  },

  methods: {
    onToRace(id, race) {
      if (
        id.toString() !== this.$route.params.id ||
        race.toString() !== this.$route.params.race
      ) {
        this.$router.push({
          name: 'raceDetail',
          params: { id: id, race: race },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.upcoming {
  display: none;
  @media (min-width: 960px) {
    display: block;
  }
}

.race {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: 10px;
  margin-top: 30px;
  user-select: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  .info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 30%;
    max-width: 30%;
    min-height: 50px;
    padding: 8px;
    padding-right: 35px;
    color: #fff;
    background: linear-gradient(
      180deg,
      var(--colorOrange) 0%,
      var(--colorOrange2)
    );
    &:before {
      content: '';
      -webkit-clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
      clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
      width: 15px;
      height: 100%;
      background: linear-gradient(
        180deg,
        var(--colorOrange) 0%,
        var(--colorOrange2)
      );
      position: absolute;
      right: -14px;
      top: 0;
    }
    @media (min-width: 1200px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .info .title {
    max-width: 100%;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}
.row {
  &:first-child .runner {
    margin-top: 0;
  }
  .runner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    padding: 20px 24px;
    background: white;
    cursor: pointer;
  }
  .runner .name {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .runner .icon {
    width: 20px;
    cursor: pointer;
  }
}
</style>
