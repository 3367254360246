import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/styles/global.scss'
import '@/helper/filter'
import Notifications from 'vue-notification'

Vue.use(Notifications)
Vue.use(Vuelidate)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  },
}).$mount('#app')
