<template lang="pug">
.app
  RouterView.c1
  RightBar.c2
  notifications(classes='p2w-vue-notification')
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '@/components/layout/NavBar.vue'
import RightBar from '@/components/layout/RightBar.vue'

export default {
  components: {
    NavBar,
    RightBar,
  },
  computed: mapGetters('bet', ['totalBet']),
}
</script>
<style lang="scss" scoped>
.app {
  display: grid;
  gap: 0 15px;
  grid:
    'c1 c1 c1'
    'c2 c2 c2'
    /1fr 3fr 2fr;
  @media (min-width: 960px) {
    grid:
      'c1 c1 c2'
      /1fr 3fr 2fr;
  }
}
</style>
