import Vue from 'vue'
import api from '@/plugins/api'
import { getTimeStamp } from '@/helper/get-time-stamp'

const state = () => ({ data: '', loading: false, error: '' })

const mutations = {
  LOADING: s => (s.loading = true),
  LOADED: s => (s.loading = false),
  SET_DATA: (s, data) => (s.data = data),
}

const actions = {
  async doVerifyBetAsync({ rootState, commit, dispatch }) {
    commit('LOADING')
    commit('bet/UPDATE_MSG_ERROR_BEFORE_BET', null, { root: true })
    const time = getTimeStamp()
    try {
      const itemsBet = rootState.bet.items.map((item, index) => {
        // Black-Lay
        return {
          cli: `TestBDM${time}${++index}`,
          eid: item.eventId,

          bt: item.typeBet === item.fWIN ? 'Win' : 'Place',
          st: item.typeBet === item.fWIN ? 'FWIN' : 'FPLC',

          sel: item.cid,
          bamt: item.stake,
          tamt: item.stake,
          rd: item.odds,
          m1: 0,
          m2: 0,
          fb: false, // bonus cash
          bm: '', // price pump
        }
      })
      const bet = {
        ut: rootState.auth.accessToken,
        uip: rootState.auth.userIp,
        ssb: true,
        smb: false,
        mbamt: 0,
        ctc: '',
        brl: itemsBet,
      }
      const { data } = await api.post(`b/BetVerify?rand=${time}`, bet)
      if (data.brl.length !== 0) {
        const tempBet = rootState.bet.items.map((bet, i) => {
          // update od bet
          if (data.brl[i].ec[0] === 311 && data.brl[i].cd) {
            // 311 -> odds change
            const odds = data.brl[i].cd
            const result = {
              ...bet,
              sts: '',
              odds: odds,
              es: data.brl[i].es,
              em: data.brl[i].em || '',
            }
            return result
          }

          return {
            ...bet,
            sts: '',
            es: data.brl[i].es,
            em: data.brl[i].em || '',
          }
        })
        const result = tempBet.every(el => el.es === 'Open' && !el.em)
        if (result) {
          dispatch('betting/quickBet/quickBetAsync', bet, { root: true })
        } else {
          Vue.notify({
            type: 'error',
            title: 'Error',
            text: 'Your bet/s have not been placed',
          })
          commit('bet/SET_LIST_BET_VERIFY', [...tempBet], { root: true })
        }
      } else {
        console.log(data.brl[0].em)
      }
    } catch (e) {
      console.log(e)
    } finally {
      commit('LOADED')
    }
  },
}

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
}
