const state = () => ({
  data: 'openBet',
})
const mutations = {
  SET_DATA: (s, data) => (s.data = data),
}

const actions = {
  setActiveTab({ commit }, payload) {
    commit('SET_DATA', payload)
  },
}

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
}
