import { render, staticRenderFns } from "./BaseInputStake.vue?vue&type=template&id=7435d72d&scoped=true&lang=pug&"
import script from "./BaseInputStake.vue?vue&type=script&lang=js&"
export * from "./BaseInputStake.vue?vue&type=script&lang=js&"
import style0 from "./BaseInputStake.vue?vue&type=style&index=0&id=7435d72d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7435d72d",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/Users/hiepngo/Code/Appscyclone/play2win/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import BaseValidation from '@/components/base/BaseValidation.vue'
autoInstallComponents(component, {BaseValidation})
