<template lang="pug">
.result
  .title.c1
    .rank {{ parseRank(item.rank) }}
    .name {{ `${item.runnerNumber}. ${item.outcomeName}` }}
</template>

<script>
import { objectProp, numberProp } from '@/helper/props'

const CONVERT_RANK = {
  1: 'Winner',
}
export default {
  props: {
    item: objectProp(),
    i: numberProp(),
  },
  data() {
    return {
      CONVERT_RANK,
    }
  },
  methods: {
    parseRank(x) {
      return this.CONVERT_RANK[x]
    },
  },
}
</script>

<style lang="scss" scoped>
.result {
  padding: 10px;
  margin-bottom: 10px;
  background: #fff;
  display: grid;
  border-top: 1px solid #e0e0e0;
  grid: 'c1 c2 '/ 8fr 4fr;
  @media (min-width: 480px) {
    padding: 10px 24px;
  }
  .title {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.25rem;
    display: grid;
    grid: auto-flow max-content/50px 1fr;
  }
  .listItem {
    .fW {
      font-size: 0.75rem;
      font-weight: 700;
      text-align: right;
    }
  }
}
</style>
