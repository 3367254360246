import $api from '@/helper/axios-instance'
import { getTimeStamp } from '@/helper/get-time-stamp'

const state = () => ({
  data: '',
  loading: false,
  error: '',
  success: false,
  status: '',
})

const mutations = {
  LOADING: s => (s.loading = true),
  SET_DATA: (s, data) => (s.data = data),
  SUCCESS: s => (s.success = true),
  SUCCEED: s => (s.success = false),
  SET_STATUS: (s, status) => (s.status = status),
  LOADED: s => (s.loading = false),
  RESET: s => Object.assign(s, state()),
}

const actions = {
  async transDebitAsync({ commit }, payload) {
    const time = getTimeStamp()
    commit('LOADING')
    try {
      const { data } = await $api.post(
        `Transactions/debit?rand=${time}`,
        payload
      )
      commit('SET_STATUS', data.status || data)
      if (data.status === 'OK') commit('SUCCESS')
      else commit('SUCCEED')
    } catch (err) {
      commit('SUCCEED')
    }
  },
  reset({ commit }) {
    commit('RESET')
  },
}

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
}
