<template lang="pug">
Betslip
</template>
<script>
import Betslip from '@/components/block/betslip'
export default {
  components: {
    Betslip,
  },
}
</script>
