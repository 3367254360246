<template lang="pug">
RaceDetail(v-if='data', type='HORS', :listRacing='data')
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RaceDetail from '@/components/block/race-detail'
export default {
  components: { RaceDetail },

  computed: {
    ...mapState('racing/list', ['data']),
  },

  created() {
    this.getRacingList()
    this.getInfoUser()
  },

  methods: {
    ...mapActions({
      getInfoUser: 'auth/getInfoUser',
      getRacingList: 'racing/list/getAsync',
    }),
  },
}
</script>
