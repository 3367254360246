<template lang="pug">
.header-bet
  .bet-slip(
    @click='$emit("clickBetSlip")',
    :class='{ active: dataActiveTabs === TITLE_BET.BETSLIP }'
  ) BET SLIP
  .open-bet(
    @click='$emit("clickOpenBet")',
    :class='{ active: dataActiveTabs === TITLE_BET.OPENBET }'
  ) OPEN BETS
</template>

<script>
import { mapState } from 'vuex'
import { TITLE_BET } from '@/assets/constants'
import { booleanProp } from '@/helper/props'
export default {
  props: {
    activeBetSlip: booleanProp(),
  },

  data() {
    return {
      TITLE_BET,
    }
  },

  computed: {
    ...mapState('activeTabs', {
      dataActiveTabs: 'data',
    }),
  },
}
</script>

<style lang="scss" scoped>
.header-bet {
  position: absolute;
  top: -54px;
  left: 0;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  z-index: 10;
  @media (min-width: 960px) {
    display: none;
  }
}

.header-bet .bet-slip,
.header-bet .open-bet {
  position: relative;
  padding: 15px;
  color: #fff;
  background-color: #1e1e1e;
  z-index: 10;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    z-index: -1;
  }
  &.active:before {
    opacity: 0.25;
    background-color: var(--colorOrange);
  }
}
.header-bet .bet-slip {
  &.active + .open-bet:after {
    left: -82px;
    width: 82px;
    opacity: 1;
  }
}

.header-bet .open-bet {
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    height: 2.6px;
    background-color: var(--colorOrange);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  &.active:after {
    opacity: 1;
  }
}
</style>
