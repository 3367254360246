<template>
  <div class="loader" :style="{ width: `${width}px` }">
    <svg class="circular" viewBox="25 25 50 50">
      <circle
        class="path"
        :style="{ stroke: `${stroke}` }"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="3"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>
<script>
import { stringProp, numberProp } from '@/helper/props.js'
export default {
  props: {
    width: numberProp(20),
    stroke: stringProp('#fff'),
  },
}
</script>
<style lang="scss" scoped>
.loader {
  position: relative;
  margin: 0 auto;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  transform-origin: center center;
  animation: rotate 2s linear infinite;
}

.path {
  stroke: white;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-dasharray: 1, 200;
  animation: dash 1.5s ease-in-out infinite;
}
</style>
