<template lang="pug">
.betSlip
  .content(v-if='totalBet > 0', :class="{ 'shake-now': isShake, 'active-lay': isActiveLay }")
    .betWrap
      BetItem.bet(
        v-for='(item, key) in listBet',
        :key='key',
        :item='item',
        :index='key',
        :is-confirm='isSubmit'
      )
    .lineEdit
      p or Choose Your Stake Size
      p.editBtn Edit stakes
    .listBtn
      BaseButton(
        v-for='(option, i) in options',
        :key='i',
        @click='handlePlusStakeBet(option.value)'
      ) {{ option.label }}
    .submitBtn
      BaseButton.cancel(v-if='!isSubmit', @click='doClearBet') Cancel
      BaseButton.cancel(v-else, @click='doCloseConfirm') {{ statusSuccess ? "Reuse Selections" : "Cancel" }}
      BaseButton.placeBet(
        v-if='isConfirm',
        :disabled='isError',
        @click='open = true',
        type='orange'
      ) {{ isSubmit ? "Confirm" : "Place bet" }}
      BaseButton.placeBet(
        v-else,
        type='orange',
        :disabled='isError',
        @click='handleQuickBet'
      ) Quick bet
    .lineAlert
      BaseIcon(name='rounded-info')
      span.text {{ noteBet }}
    .quickBet
      span.note Confirm bets before placing
      BaseSwitch(v-model='isConfirm')
  .empty(v-else)
    p Your Bet Slip is Empty
  .loadingContent(v-if='loadingTransDebit')
    p.text Your bet will be placed in...
    .load
      BaseLoadingSpinner(:width='70', :stroke='`#000`')
      span.numberLoad {{ numberTime }}
  //- Modal
  BaseModal(:open='open', @click='open = false', :classess='setColor')
    p.textModal Are you sure you want to place the bet?
    .contentModal(v-if='getListBet')
      .inputGroup
        input(
          type='text',
          :value='getListBet.odds',
          :class='setColor',
          readonly
        )
        span.label(:class='setColor') Odds
      .inputGroup
        input(
          type='text',
          :value='getListBet.stake',
          :class='setColor',
          readonly
        )
        span.label(:class='setColor') Stake
    .btnModal
      button(@click='open = false') No
      button.green(@click='submitBet') Yes
  //- End Modal
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { countDownTimer } from '@/helper/count-down-timer.js'
import { getTimeStamp } from '@/helper/get-time-stamp'
import { TYPE_TIMER } from '@/assets/constants'
import { STATUS_RACE } from '@/assets/constants'
import BetItem from './BetItem'
import { TITLE_BET } from '@/assets/constants'
export default {
  components: {
    BetItem,
  },

  data() {
    return {
      open: false,
      options: [
        { label: '+25', value: 25 },
        { label: '+50', value: 50 },
        { label: '+100', value: 100 },
        { label: '+10,000', value: 10000 },
        { label: '+20,000', value: 20000 },
        { label: '+30,000', value: 30000 },
      ],
      isConfirm: true,
      isSubmit: false,
      isShake: false,
      isActiveLay: false,
      numberTime: 1,
    }
  },

  computed: {
    ...mapGetters('bet', [
      'listBet',
      'totalBet',
      'totalStakeFinal',
      'esReturnFinal',
    ]),

    ...mapState('auth', ['accessToken', 'userId', 'sid', 'multiplier']),

    ...mapState('betting/quickBet', {
      loadingQuickBet: 'loading',
      statusSuccess: 'success',
    }),

    ...mapState('raceDetail', ['loading', 'data']),
    ...mapState('betting/transStatus', ['betListProcessing']),
    ...mapState('bet', ['betActive', 'isError']),
    ...mapState('betting/transDebit', {
      loadingTransDebit: 'loading',
      successDebit: 'success',
      statusDebit: 'status',
    }),

    noteBet() {
      if (!this.multiplier) return 'Min Bet: 1 Max Bet: 100 Max Winning: 1000'
      return `Min Bet: 1 Max Bet: ${this.multiplier}00 Max Winning: ${this.multiplier}000`
    },
    getListBet() {
      return this.listBet.find(item => item)
    },
    setColor() {
      if (!this.getListBet) return
      return this.getListBet.betType === 'BACK' ? 'secondary' : 'accent'
    },
  },

  watch: {
    listBet(val) {
      if (!val) return
      this.setActiveTab(TITLE_BET.BETSLIP)
      this.doCloseConfirm()
      this.handleActiveBetType(val[0])
      this.handleIsShaked()
    },
  },

  methods: {
    ...mapActions({
      doPlusStakeBetItem: 'bet/plusStakeBetItem',
      doPlaceBet: 'betting/transDebit/transDebitAsync',
      doOpenBet: 'betting/openBet/openBetAsync',
      reset: 'bet/reset',
      resetBetAfterBetSuccess: 'bet/resetBetAfterBetSuccess',
      doFindAndUpdateBet: 'bet/findAndUpdateBet',
      resetWattingBetProcessing: 'betting/transStatus/reset',
      doRemoveBet: 'bet/removeBet',
      setActiveTab: 'activeTabs/setActiveTab',
    }),

    ...mapMutations('betting/transDebit', ['LOADING', 'LOADED']),
    handleActiveBetType(bet) {
      if (!bet) return

      if (bet.betType === 'LAY') this.isActiveLay = true
      else this.isActiveLay = false
    },

    handleIsShaked() {
      if (screen.availWidth >= 960) this.isShake = true
      setTimeout(() => (this.isShake = false), 500)
    },

    doCloseConfirm() {
      if (this.statusSuccess) {
        if (this.betListProcessing.length > 0) this.resetWattingBetProcessing()
        this.resetBetAfterBetSuccess()
      }
      this.isSubmit = false
    },

    doClearBet() {
      this.reset()
    },

    handlePlusStakeBet(val) {
      if (!isNaN(val) || !val) {
        this.doPlusStakeBetItem({ stake: val })
      }
    },

    handlePlaceBet() {
      if (this.isSubmit) this.submitBet()
      else this.isSubmit = true
    },

    handleQuickBet() {
      this.submitBet()
    },

    handleCountDownTimer() {
      if (this.numberTime > -1) {
        setTimeout(() => {
          this.numberTime -= 1
          this.handleCountDownTimer()
        }, 1000)
      }
      if (this.numberTime < 0) {
        this.LOADED()
        this.numberTime = 1
        this.showStatusBet()
      }
    },

    showStatusBet() {
      if (this.successDebit) {
        this.$notify({
          type: 'success',
          text: 'Your bet has been successfully placed.',
        })
        this.setActiveTab(TITLE_BET.OPENBET)
        return
      }
      this.$notify({
        type: 'error',
        text: this.statusDebit || 'Your bet has been placed failed.',
      })
    },

    async submitBet() {
      if (this.isError) return
      const listBet = this.listBet.find(item => item)
      const timer = countDownTimer(listBet.timeOfRace)
      const convertTimeEpoch = new Date(listBet.timeOfRace).getTime()
      const time = getTimeStamp()
      this.open = false
      if (
        this.data.status !== STATUS_RACE.OPEN &&
        timer.type === TYPE_TIMER.TIMEOUT
      ) {
        this.$notify({
          type: 'error',
          text: 'Betting for this race is closed.',
        })
      }
      if (this.totalStakeFinal > 0 && this.data.status === STATUS_RACE.OPEN) {
        this.handleCountDownTimer()
        await this.doPlaceBet({
          transaction: {
            id: `RB${time}`,
            refId: `04f2b0214c53b00508a51d9${time}`,
            amount: listBet.stake,
            odds: listBet.odds,
            bettype: listBet.betType,
            timeOfBet: Date.now(),
            timeOfRace: convertTimeEpoch,
            runnerName: listBet.outcomeName,
            meetingName: listBet.meetingName,
            meetingId: listBet.meetingId,
            eventNo: listBet.eventNumber,
            runnerNo: listBet.runnerNumber,
          },
          sid: this.sid,
          userId: this.userId,
          uuid: this.sid,
          currency: 'HKD',
          game: {
            // id: '15bd7469b5d696de26e9f17d1',
            // details: {
            //   table: {
            //     id: 'wzg6kdkad1oe7m5k1',
            //     vid: null,
            //   },
            // },
          },
        })
        await this.doOpenBet({
          _accessToken: this.accessToken,
          isRacebook: true,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.betSlip {
  display: block;
  position: relative;
  margin-top: 5px;
  @media (min-width: 960px) {
    display: none;
    margin-top: 0;
  }
  &.show {
    display: block;
  }
}
.content {
  position: relative;
  border: 4px solid var(--colorSecondary);
  padding: 12px;
  background-color: #fff;
  &.active-lay {
    border-color: var(--colorThird);
  }
  .bet {
    &:not(:first-child) {
      padding-top: 20px;
    }
  }
  .lineEdit {
    padding-top: 10px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    .editBtn {
      text-transform: uppercase;
      color: var(--colorOrange2);
      font-weight: 400;
      cursor: pointer;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
    p {
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.0333333333em;
      color: #000;
    }
  }
  .listBtn {
    display: grid;
    gap: 8px;
    grid:
      'c1 c2 c3'
      'c4 c5 c6'/
      1fr 1fr 1fr;
    button {
      background: var(--gradientOrange);
      height: 30px;
      color: #fff;
      font-size: 0.75rem;
      letter-spacing: 0.0892857143em;
      font-weight: 500;
      border-radius: 0;
      font-family: var(--fontFamilyLato);
    }
  }
  .esReturn {
    text-align: right;
    p {
      font-size: 12px;
      color: #000;
      font-weight: 400;
    }
  }
  .submitBtn {
    display: grid;
    grid: 'c1 c2'/ 1fr 1fr;
    gap: 4px;
    padding-top: 12px;
    button {
      height: 36px;
      text-transform: uppercase;
      color: #424242;
      font-size: 0.875rem;
      cursor: pointer;
      border-radius: 0;
      font-family: var(--fontFamilyLato);
      letter-spacing: 0.0892857143em;
    }
    .cancel {
      background: white;
      border: 1px solid #979797;
      font-weight: 400;
      border-radius: 0;
    }
    .placeBet {
      font-weight: 500;
    }
  }
  .lineAlert {
    padding-top: 12px;
    color: var(--colorOrange);
    font-size: 0.75rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    .svgIcon {
      width: 24px;
      height: 24px;
      margin-right: 5px;
      display: inline-block;
    }
    .text {
      line-height: 26px;
      font-size: 0.75rem;
    }
  }

  .quickBet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    .note {
      font-size: 0.875rem;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
    }
  }
}
.loadingContent {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  .text {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    margin-bottom: 20px;
  }
  .load {
    position: relative;
    .numberLoad {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 16px;
      color: #000;
      transform: translate(-50%, -50%);
    }
  }
}
.empty {
  text-align: center;
  display: none;
  border: 1px solid #979797;
  @media (min-width: 960px) {
    padding: 40px 10px;
    display: block;
  }
  p {
    font-size: 12px;
    letter-spacing: 0.0333333333em;
  }
}

.textModal {
  margin: 0;
  padding: 6px 14px 20px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.contentModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
}
.inputGroup {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  padding-bottom: 30px;
  &:not(:last-child) {
    padding-right: 24px;
  }
  input {
    border: 1px solid rgba(0, 0, 0, 0.38);
    color: #000;
    width: 100%;
    min-height: 56px;
    border-radius: 4px;
    padding: 0 12px;
    font-size: 16px;
    &.secondary {
      background-color: var(--colorSecondary);
    }
    &.accent {
      background-color: var(--colorThird);
    }
  }
  .label {
    position: absolute;
    top: -11px;
    left: 12px;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 400;
    z-index: 9;
    &.secondary {
      background-color: var(--colorSecondary);
    }
    &.accent {
      background-color: var(--colorThird);
    }
  }
}
.btnModal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    border-radius: 0;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0 8px;
    height: 36px;
    min-width: 64px;
    outline: none;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .green {
    background-color: #4caf50;
    color: #fff;
    border: 1px solid #4caf50;
    margin-left: 8px;
  }
}
</style>
