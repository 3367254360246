<template>
  <span class="display-timer" :class="typeDisplay">
    {{ timeDisplay }}
  </span>
</template>
<script>
import { stringProp } from '@/helper/props.js'
import { countDownTimer } from '@/helper/count-down-timer.js'

export default {
  props: {
    time: { type: [Number, String] },
    type: stringProp(),
  },
  data() {
    return {
      timeDisplay: '--',
      countDown: this.time,
      autoCountDown: '',
      typeDisplay: '',
      isOpen: true,
    }
  },
  watch: {
    time(val) {
      this.countDown = val
    },
  },
  created() {
    this.autoCountDown = setInterval(this.onCountDown, 1000)
  },
  destroyed() {
    clearInterval(this.autoCountDown)
  },
  methods: {
    onCountDown() {
      const { display, type } = countDownTimer(this.countDown)
      if (type === 'time_out' && this.isOpen) {
        this.isOpen = false
        this.$emit('close')
      }
      this.timeDisplay = display
      this.typeDisplay = type
    },
  },
}
</script>
<style lang="scss" scoped>
.display-timer {
  width: 65px;
  overflow: hidden;
  text-overflow: hidden;
  white-space: nowrap;
  font-size: 10px;
  text-align: center;
  border-radius: 2px;
  user-select: none;
}
.normal,
.time_out,
.waiting_start {
  color: white;
  padding: 4px;
  border-radius: 3px;
  font-weight: 600;
}
.normal {
  background: #000;
}
.time_out,
.waiting_start {
  background: #ff0000;
}

.hours,
.minutes {
  color: #a8a8a8;
  font-weight: 600;
}
</style>
