const state = () => ({
  items: [],
  itemsBeting: [],
  betActive: 0,
  isError: true,
})

const getters = {
  totalBet: s => s.items.length,
  listBet: s => s.items,
  listBetConfirm: s => s.itemsBeting,
  totalStakeFinal: s => s.items.reduce((a, b) => a + parseFloat(b.stake), 0),
  esReturnFinal: s =>
    s.items.reduce((a, b) => a + parseFloat(b.stake) * parseFloat(b.odds), 0),
}

const mutations = {
  SET_ERROR: (s, isError) => (s.isError = isError),
  ADD_TO_BET: (s, p) =>
    s.items.length <= 0 ? s.items.push(p) : s.items.splice(p, 1, p),
  ADD_TO_BET_CONFIRM: (s, p) => s.itemsBeting.unshift(p),
  REMOVE_TO_BET: (s, p) => s.items.splice(p, 1),
  UPDATE_STAKE_BET_ITEM: (s, { index, stake }) => {
    if (stake !== '') s.items[index].stake = Number(stake)
  },
  PLUS_STAKE_BET_ITEM: (s, { stake }) => {
    if (!s.betActive || typeof stake !== undefined);
    if (s.items[s.betActive].stake === '') s.items[s.betActive].stake = 0
    s.items[s.betActive].stake += stake
  },
  SET_ACTIVE_BET_ITEM: (s, p) => (s.betActive = p),
  SET_LIST_BET_VERIFY: (s, bet) => (s.items = bet),
  UPDATE_MSG_ERROR_BEFORE_BET: s =>
    (s.items = s.items.map(item => ({ ...item, em: '' }))),
  UPDATE_ITEM_BET_BY_INDEX: (s, { index, status, stake }) => {
    s.items[index].sts = status
    s.items[index].stake = stake
  },
  RESET: s => Object.assign(s, state()),
}

const actions = {
  setError({ commit }, payload) {
    commit('SET_ERROR', payload)
  },
  addItemToBet({ commit }, payload) {
    commit('ADD_TO_BET', { ...payload })
  },
  updateStakeBetItem({ commit }, payload) {
    commit('UPDATE_STAKE_BET_ITEM', payload)
  },
  plusStakeBetItem({ commit }, payload) {
    commit('PLUS_STAKE_BET_ITEM', payload)
  },
  setActiveBet({ commit }, payload) {
    commit('SET_ACTIVE_BET_ITEM', payload)
  },
  removeBet({ commit }, payload) {
    commit('REMOVE_TO_BET', payload)
  },
  removeBetByInfo({ commit, state }, payload) {
    const index = state.items.findIndex(
      item =>
        item.eventId === payload.eventId &&
        item.odd === payload.odd &&
        item.specialType === payload.specialType &&
        item.cid === payload.cid
    )
    commit('REMOVE_TO_BET', index)
  },
  resetBetAfterBetSuccess({ state, commit, dispatch }) {
    const tempBet = state.items.map(bet => ({
      ...bet,
      sts: '',
      em: '',
    }))
    commit('SET_LIST_BET_VERIFY', [...tempBet])
    dispatch('betting/quickBet/reset', null, { root: true })
  },

  findAndUpdateBet({ commit, state }, { key, status, stake }) {
    const indexItem = state.items.findIndex(item => item.key === key)
    commit('UPDATE_ITEM_BET_BY_INDEX', { index: indexItem, status, stake })
  },

  reset({ commit }) {
    commit('RESET')
  },
}

export default {
  namespaced: true,
  state: state,
  getters,
  mutations,
  actions,
}
