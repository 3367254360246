import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// modules
import racing from './modules/racing'
import raceDetail from './modules/raceDetail'
import bet from './modules/bet'
import betting from './modules/betting'
import auth from './modules/auth'
import activeTabs from './modules/active-tabs'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    bet,
    raceDetail,
    racing,
    betting,
    auth,
    activeTabs,
  },
  plugins: [
    createPersistedState({
      key: 'play2win',
      storage: window.localStorage,
      paths: ['bet'],
    }),
  ],
})

export default store
